// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//Ex:
// $theme-primary: mat.define-palette(mat.$indigo-palette);
// $theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $theme-warn: mat.define-palette(mat.$red-palette);

// $my-typography: mat.define-typography-config(
//   $font-family: "Roboto, 'Helvetica Neue', sans-serif",
// );

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $theme-primary,
//       accent: $theme-accent,
//       warn: $theme-warn,
//     ),
//     typography: $my-typography,
//   )
// );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($theme);
// @include mat.all-component-typographies($my-typography);

.mat-mdc-snack-bar-container {
  &.snackbar-gray {
    --mdc-snackbar-container-color: lightgray;
    --mdc-snackbar-supporting-text-color: black;
  }
}
.mat-mdc-snack-bar-container {
  &.snackbar-green {
    --mdc-snackbar-container-color: #28a745;
    --mdc-snackbar-supporting-text-color: white;
  }
}
.mat-mdc-snack-bar-container {
  &.snackbar-red {
    --mdc-snackbar-container-color: #dc3545;
    --mdc-snackbar-supporting-text-color: white;
  }
}

//AG grid version 33 uses a different method of setting the indentation that broke legacy themed grids
//This replicates the original behavior for 100 subfolders
@for $i from 1 through 100 {
  .ag-row-group-indent-#{$i} {
    --ag-indentation-level: #{$i} !important;
  }
}

@import '~ag-grid-enterprise/styles/ag-grid.min.css';
@import '~ag-grid-enterprise/styles/ag-theme-balham.min.css';
@import '~ag-grid-enterprise/styles/ag-theme-material.min.css';
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
  --alert-height: 20px;
  --checkbox-size: 20px;
  --menu-height: 64px;
}

.ag-theme-material {
  --ag-cell-horizontal-padding: 8px;
  --ag-row-group-indent-size: calc(var(--ag-cell-horizontal-padding) + 18px);
}

.ag-theme-balham {
  --ag-row-hover-color: var(--bg-tertiary) !important;
}

html {
  height: 100%;
}

body {
  height: 100%;
  padding: 0px;
  padding-top: var(--menu-height);
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

iframe[name='JSD widget'] {
  display: block;
  /* Matches Iframe right side padding */
  top: 8px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.container-padding {
  padding: 0 15px;
}

.loading {
  display: block;
  width: 32px;
  height: 32px;
  margin: 16px auto 0px auto;
  background: url('../node_modules/@equityeng/e2g-ng-ui/assets/loading.gif') no-repeat;
  
  background-size: 32px 32px;
}


.pointer {
  cursor: pointer;
}

.shadow-box {
  padding: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}

.form-checkbox {
  height: 20px;
  width: 20px;
}

a {
  color: var(--interactive-fg-primary-default);
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.active-link {
  display: flex;
  align-items: center;
}

cdk-virtual-scroll-viewport {
  height: 100%;
}

mat-drawer {
  min-width: 300px;
}

.cdk-overlay-container {
  z-index: 10000 !important;
}

.mat-tooltip {
  font-size: larger !important;
}

/* Buttons */
/* TODO: Rename to btn-primary */
.e2g-btn-solid-primary {
  font-size: 14px;
  background-color: var(--button-bg-primary-default);
  color: var(--button-fg-primary-default) !important;
}

.e2g-btn-solid-primary:hover {
  background-color: var(--button-bg-primary-hover);
}

.e2g-btn-solid-primary:active {
  background-color: var(--button-bg-primary-pressed);
}

.e2g-btn-solid-primary:disabled {
  background-color: var(--button-bg-primary-disabled);
}

/* TODO: Rename to btn-secondary */
.e2g-btn-outline-primary {
  font-size: 14px;
  background-color: var(--button-bg-secondary-default);
  color: var(--button-fg-secondary-default);
  border-color: var(--button-border-secondary-default);
}

.e2g-btn-outline-primary:hover {
  background-color: var(--button-bg-secondary-hover);
  color: var(--button-fg-secondary-hover);
  border-color: var(--button-border-secondary-hover);
}

.e2g-btn-outline-primary:active {
  background-color: var(--button-bg-secondary-pressed);
  color: var(--button-fg-secondary-pressed);
  border-color: var(--button-border-secondary-pressed);
}

.e2g-btn-outline-primary:disabled {
  background-color: var(--button-bg-secondary-disabled);
  color: var(--button-fg-secondary-disabled);
  border-color: var(--button-border-secondary-disabled);
}

.e2g-btn-tertiary {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--button-fg-tertiary-default);
  border: none;
}

.e2g-btn-tertiary:hover {
  color: var(--button-fg-tertiary-hover);
}

.e2g-btn-tertiary:active {
  color: var(--button-fg-tertiary-pressed);
  box-shadow: none;
}

.e2g-btn-tertiary:disabled {
  color: var(--button-fg-tertiary-disabled);
  box-shadow: none;
}

.e2g-btn-tertiary:focus {
  box-shadow: none;
}

.icon-secondary,
.icon-secondary-info {
  color: var(--interactive-fg-secondary-default);
}

.icon-secondary:hover,
.icon-secondary-info:hover {
  color: var(--interactive-fg-secondary-hover);
}

.icon-secondary {
  cursor: pointer !important;
}

.icon-secondary:active {
  color: var(--interactive-fg-secondary-pressed);
}

:disabled .icon-secondary {
  cursor: default !important;
  color: var(--interactive-fg-secondary-disabled);
}

/* TODO: Replace references to class with appropriate contextual class name  */
.e2g-primary-grey {
  color: var(--Color-neutral-500);
}

.e2g-disabled-grey {
  color: var(--interactive-fg-primary-disabled);
}

.e2g-padding {
  padding: 20px 25px 10px 25px;
}

.e2g-h2 {
  font-weight: 700;
  font-size: 22px;
  color: var(--system-fg-information);
  padding-bottom: 25px;
}

.e2g-white {
  color: var(--interactive-fg-primary-inverse);
}

.e2g-blue {
  color: var(--interactive-fg-tertiary-default);
}

.e2g-warning-bar {
  width: 200px;
  background-color: var(--system-bg-primary-danger);
  color: var(--interactive-fg-primary-inverse);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.e2g-radio {
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  accent-color: var(--input_control-fg-selected);
}

.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.radio-item > label {
  margin-left: 0.5em;
  margin-bottom: 0em;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-111 {
  flex: 1 1 1px;
}

app-detail,
app-equipment,
sage-apipe,
app-comments {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
}

.flow-select {
  padding: 20px 30px 30px 30px !important;
}

.tab-pane.active {
  height: 100%;
}

.nav-link {
  color: var(--tab-fg-secondary-default);
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
}

.nav-link:hover {
  color: var(--tab-fg-secondary-hover) !important;
}

.nav-link:active {
  color: var(--tab-fg-secondary-pressed) !important;
}

.nav-link:disabled {
  color: var(--tab-fg-secondary-disabled) !important;
}

.nav-link.active {
  color: var(--tab-fg-primary-default) !important;
}

.nav-link.active:hover {
  color: var(--tab-fg-primary-hover) !important;
}

.nav-link.active:active {
  color: var(--tab-fg-primary-pressed) !important;
}

.nav-link.active:disabled {
  color: var(--tab-fg-primary-disabled) !important;
}

.nav-tabs {
  background-color: var(--bg-tertiary);
}

.nav-tabs .nav-link {
  border: 1px solid var(--tab-border-bounds);
  background-color: var(--tab-bg-secondary);
}

.nav-tabs .nav-link.active {
  background-color: var(--tab-bg-primary);
}

/* TODO remove this tab specific padding and instead define heading padding in single class */
.header-table > .nav-item:first-child {
  margin-left: 25px !important;
}

.slide-out-header-border,
.sub-nav-tabs {
  border-bottom: 1px solid var(--border-bounds);
}

.sub-nav-tabs > .nav-item > .active {
  border-bottom: 1px solid var(--tab-border-selected);
  margin-bottom: -1px;
}

.sub-nav-tabs .nav-link {
  padding-top: 0;
}

.ag-cell-input {
  font-weight: inherit;
  color: inherit;
  border: none !important;
  width: 100%;
}

.grid-checkbox {
  height: 16px;
  width: 16px;
  vertical-align: sub;
}

.rate-growth {
  font-weight: bold;
}

.rate-growth::after {
  content: '*';
  margin-left: 2px;
}

.required::before {
  content: '*';
  color: var(--system-fg-error);
  padding-right: 4px;
}

.thickness-warning {
  font-weight: bold;
  color: var(--system-fg-error) !important;
}

.thickness-growth {
  font-weight: bold;
}

app-box {
  flex-grow: 1;
}

mat-form-field {
  width: 100%;
}

mat-form-field .is-default {
  font-style: italic;
}

.tab-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mat-mdc-menu-content {
  --mdc-theme-text-primary-on-background: var(--interactive-fg-primary-default);
  --mdc-typography-body1-font-size: 14px;
}

.mat-mdc-menu-item:not(.mat-mdc-menu-item-submenu-trigger) {
  padding: 10px 20px !important;
}

.mat-mdc-menu-item:hover {
  background-color: var(--bg-tertiary);
}

.scrollbar-content {
  overflow-y: auto !important;
}

.ag-center-cols-container {
  min-width: 100%;
}

.ag-header {
  background-color: var(--bg-primary) !important;
}

.ag-header-cell-text,
.ag-header-group-text {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: var(--interactive-fg-secondary-default) !important;
}

.ag-header-group-cell-label {
  justify-content: center;
}

.ag-header-cell {
  border: none !important;
  outline: none !important;
}

.ag-theme-balham .ag-row {
  font-size: 14px !important;
  color: var(--interactive-fg-primary-default) !important;
}

.ag-theme-material .ag-row {
  font-size: 14px !important;
}

.ag-header-cell {
  font-size: 14px !important;
}

.ag-header-center .ag-header-cell-label {
  justify-content: center !important;
}

.ng-multiselect-wide .dropdown-btn .selected-item {
  max-width: 100% !important;
}

app-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.required {
  color: var(--system-fg-error);
}

ag-grid-angular button {
  line-height: 1;
  border: 1px solid var(--pill-border-bounds);
  padding: 1px 5px;
  border-radius: 4px;
  color: var(--interactive-fg-primary-default);
  background-color: var(--interactive-fg-primary-inverse);
  margin-top: 2px;
  vertical-align: inherit;
}

e2g-fluid-designer ag-grid-angular select {
  border: 1px solid var(--input_control-border-bounds);
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 500;
  color: var(--interactive-fg-primary-default);
  background-color: var(--interactive-fg-primary-inverse);
  text-transform: uppercase;
  padding: 2px 6px;
}

e2g-fluid-designer ag-grid-angular .dropdown-header {
  padding: 0px;
}

e2g-fluid-designer ag-grid-angular .ag-header-cell-comp-wrapper {
  justify-content: end;
}

e2g-fluid-designer .ag-header-cell-text {
  text-transform: uppercase;
}

.fluid-designer-modal mat-dialog-container {
  overflow: visible !important;
}

app-pdf-viewer {
  flex: 1 1 1px;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
ag-grid-angular,
app-sage-diag,
app-sage-tree,
app-hierarchy-tree,
pdf-viewer {
  height: 100%;
}

.dialog-content {
  height: 100%;
  padding: 24px;
}

app-attachments {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* TODO detemine if class name or color should be changed to match */
.danger-color {
  color: var(--system-fg-error);
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.cdk-drop-list-dragging {
  cursor: grabbing !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ag-root-wrapper {
  border-radius: 4px;
}

.font-size-1-5 {
  font-size: 1.5rem;
}

.e2g-backdrop {
  background-color: var(--bg-inverse);
  opacity: 0.3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  z-index: 3;
  visibility: visible;
}

.moc-container app-br-text :first-child {
  margin-top: 0.5rem;
}

.e2g-slideout-title {
  padding-top: 20px;
  padding-left: 25px;
  color: var(--interactive-fg-tertiary-default);
}

select:focus {
  outline: 0px;
  border: 1px solid var(--input_control-border-selected);
  border-radius: 0.25rem;
}

.slideout-input-section-heading {
  color: var(--interactive-fg-tertiary-default);
  font-size: 16px;
  font-weight: 500;
}

.slideout-line-spacer,
.separator {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-top: 1px solid var(--border-bounds);
}

/*TODO: combine usages with slideout-line-spacer or separator*/
.seperator {
  color: var(--border-bounds);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.dashboard-line-divider {
  margin-top: 8px;
  border-top: 1px solid var(--border-bounds);
}

.dashboard-slideout-header {
  color: var(--interactive-fg-tertiary-default);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

.dashboard-data-header {
  color: var(--interactive-fg-tertiary-default);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.form-section-header {
  color: var(--interactive-fg-tertiary-default);
  font-size: 16px;
  font-weight: 700;
}

.form-title {
  color: var(--interactive-fg-tertiary-default);
  font-size: 20px;
  font-weight: 700;
}

.fit-preview-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: scale-down;
}

.e2g-card {
  border: 1px solid var(--border-bounds);
  border-radius: 4px;
}

.grid-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
}

.inp-under-check {
  margin-left: 25px;
  max-width: 250px;
}

.control-flow-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 57px;
  border-top: solid 1px var(--border-bounds);
  padding: 0px 30px;
  background-color: var(--bg-tertiary);
}

.e2g-main-body {
  height: calc(100% - 89px);
  overflow-y: auto;
}

.e2g-main-body.readonly {
  height: 100%;
}

.pointer .js-plotly-plot > .plotly > .svg-container > .main-svg > g > g > rect {
  cursor: pointer !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-button {
  margin-right: 20px;
  color: var(--system-fg-information);
}

mat-radio-button label {
  margin-bottom: 0px;
}

.slide-out-header .header-name .e2g-input-wrap .e2g-input .ng-select-container .ng-value-container .ng-value,
.slide-out-header .header-name .e2g-input-wrap .e2g-input .ng-select-container .ng-input {
  color: var(--input-fg-header) !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  letter-spacing: normal !important;
  padding: 0px !important;
}

.slide-out-header .header-name .e2g-input-wrap .e2g-input .ng-select-container {
  height: 40px !important;
}

.slide-out-header .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: var(--input-fg-header) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.slide-out-header .e2g-readonly,
.slide-out-header .ng-select-disabled .e2g-input-wrap .e2g-input .ng-select-container,
.slide-out-header .ng-select-disabled .e2g-input,
.slide-out-header .ng-select-disabled {
  background-color: var(--bg-tertiary) !important;
}

.slide-out-header .ng-select-disabled .e2g-input-wrap,
.slide-out-header .ng-select-disabled .e2g-input {
  border: 1px solid !important;
}

.slide-out-header .ng-select-disabled .ng-value-container .ng-value {
  background-color: transparent !important;
  border: transparent !important;
}

.read-only-value {
  border-bottom: 1px solid #e8e8e8 !important;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.cursor-disabled {
  cursor: default;
}

.card-body {
  padding: 5px;
}

h4 {
  margin: 1px;
}

#icon-reset {
  margin-right: 4px;
}

.ie-table {
  width: 100%;
  margin: 0;
  color: var(--system-fg-information);
}

.ie-header {
  vertical-align: middle !important;
  text-transform: uppercase;
  text-align: center;
}

.ie-grade-col {
  width: 10%;
  vertical-align: middle !important;
  text-align: center;
  font-weight: 500;
}

.ie-insp-col {
  width: 45%;
}

.ie-insp-col-single {
  width: 90%;
  vertical-align: middle;
}

.ie-html-text p,
.ie-html-text ul {
  margin: 0 !important;
}

.ie-hideToolbar .ql-toolbar {
  display: none;
}

.ie-local-quill .ql-toolbar.ql-snow {
  padding: 0;
  margin: 0;
  border: none;
  background-color: var(--bg-tertiary);
}

.ie-local-quill .ql-container.ql-snow {
  padding: 0;
  margin: 0;
  border: none;
}

.ie-local-quill .ql-editor {
  padding: 0;
  margin: 0;
}

.ql-editor {
  font-family: 'Roboto';
  font-size: 14px;
}

.fa-blank {
  visibility: hidden !important;
}

.mat-mdc-dialog-content {
  display: flex !important;
  flex-direction: column !important;
  max-height: 100% !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: var(--input_control-fg-disabled);
  --mdc-radio-disabled-unselected-icon-color: var(--input_control-border-disabled);
  --mdc-radio-unselected-focus-icon-color: var(--input_control-fg-hover);
  --mdc-radio-unselected-hover-icon-color: var(--input_control-fg-hover);
  --mdc-radio-unselected-icon-color: var(--input_control-border-bounds);
  --mdc-radio-unselected-pressed-icon-color: var(--input_control-fg-pressed);
  --mdc-radio-selected-focus-icon-color: var(--input_control-fg-hover);
  --mdc-radio-selected-hover-icon-color: var(--input_control-fg-hover);
  --mdc-radio-selected-icon-color: var(--input_control-fg-selected);
  --mdc-radio-selected-pressed-icon-color: var(--input_control-fg-pressed);
  --mat-mdc-radio-ripple-color: var(--input_control-border-bounds);
  --mat-mdc-radio-checked-ripple-color: var(--input_control-fg-selected);
}

input[type='checkbox'] {
  accent-color: var(--input_control-fg-selected);
}

mjx-container {
  margin: 0 !important;
}

/* Override styles from Sage Forms */
.sage-tab-container-padding {
  padding: 0px 0px 10px 0px !important;
}

app-sage-form-outlet .nav-tabs {
  background-color: var(--bg-tertiary) !important;
  position: sticky;
  top: 0px;
  z-index: 100;
  width: 100%;
}

/* TODO move into sage forms */
.sage-info {
  color: var(--interactive-fg-secondary-default);
}

.sage-info:hover {
  color: var(--interactive-fg-secondary-hover);
}

.sage-info:active {
  color: var(--interactive-fg-secondary-pressed);
}

/***********************************/

#select-finding-component > e2g-read-only > div {
  white-space: nowrap !important;
}

/*Mat tooltip styles*/
.mdc-tooltip__surface {
  --mdc-plain-tooltip-supporting-text-color: var(--interactive-fg-primary-default);
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-container-color: var(--bg-primary);
  max-width: 400px !important;
  padding: 10px !important;
  border: 1px solid var(--border-bounds);
  /* rgba function is needed to set opacity, not for the color */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  /* This allows newline characters to create new lines */
  white-space: pre-line;
}

.mat-mdc-menu-item {
  min-height: 40px !important;
}

app-sage-move-dlg {
  height: 100% !important;
}

app-sage-copy-dlg {
  height: 100% !important;
}

.ui-image {
  padding-top: 20px;
  width: 560px;
}

/* For drop down button labels to fit width of longest option */
.mat-menu-nowrap {
  max-width: initial !important;
}

.alert-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  border-radius: 0px;
  height: var(--alert-height);
  z-index: 9999;
}

.alert-shift {
  top: var(--alert-height) !important;
}

.alert-padding {
  padding-top: var(--alert-height) !important;
}

app-grid-header-select-all {
  /* Override width for custom headers to show ellipsis that headers show by default */
  width: 100%;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: var(--interactive-fg-secondary-default) !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.module-name .e2g-input-wrap .e2g-input,
.module-name .read-only-value {
  font-family: 'Roboto' !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  color: var(--input-fg-header) !important;
  cursor: default !important;
  width: 500px !important;
  border-radius: 4px !important;
  padding: 10px !important;
}

.module-name .read-only-value {
  line-height: 20px !important;
}

.module-menu-button span {
  font-family: 'Roboto' !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--interactive-fg-secondary-default) !important;
  letter-spacing: inherit !important;
  --mat-mdc-button-persistent-ripple-color: transparent !important;
  --mat-mdc-button-ripple-color: transparent !important;
  box-shadow: none !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  border-radius: 10px !important;
}

app-slide-input .mat-mdc-slide-toggle .mdc-form-field label {
  padding-left: 10px !important;
  margin: 0px !important;
  vertical-align: middle !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: transparent;
  --mdc-switch-selected-handle-color: var(--input_control-bg-selected);
  --mdc-switch-selected-hover-state-layer-color: transparent;
  --mdc-switch-selected-pressed-state-layer-color: transparent;
  --mdc-switch-selected-focus-handle-color: var(--input_control-bg-selected);
  --mdc-switch-selected-hover-handle-color: var(--input_control-bg-selected);
  --mdc-switch-selected-pressed-handle-color: var(--input_control-bg-selected);
  --mdc-switch-selected-focus-track-color: var(--input_control-bg-switch);
  --mdc-switch-selected-hover-track-color: var(--input_control-bg-switch);
  --mdc-switch-selected-pressed-track-color: var(--input_control-bg-switch);
  --mdc-switch-selected-track-color: var(--input_control-bg-switch);
}

.mdc-switch__ripple {
  display: none;
}

.row-driving-cml {
  -webkit-box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
  -moz-box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
  box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
}

.row-driving-cml.ag-row-selected.ag-full-width-row.ag-row-group::before,
.row-driving-cml.ag-row-selected:not(.ag-full-width-row)::before {
  -webkit-box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
  -moz-box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
  box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
}

.row-driving-cml.ag-row-hover.ag-full-width-row.ag-row-group::before,
.row-driving-cml.ag-row-hover:not(.ag-full-width-row)::before {
  -webkit-box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
  -moz-box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
  box-shadow: inset 0px 0px 0px 1px var(--system-border-error);
}

app-grid-cell-remaining-life {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: inherit !important;
  justify-content: center !important;
}

app-grid-cell-menu {
  width: 100% !important;
}

.action-cell {
  padding-right: 0px !important;
  padding-left: 16px !important;
}

.ag-cell-hover-outline:not(.ag-cell-inline-editing):hover {
  -webkit-box-shadow: inset 0px 0px 0px 1px var(--system-border-accent);
  -moz-box-shadow: inset 0px 0px 0px 1px var(--system-border-accent);
  box-shadow: inset 0px 0px 0px 1px var(--system-border-accent);
}

.checkbox-grid-cell {
  line-height: normal;
}

.mat-calendar-body-selected {
  background-color: var(--fg-primary);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: unset !important;
}

.mat-calendar-body-in-range::before {
  background-color: var(--bg-tertiary) !important;
}

#text-input-planning-horizon input {
  cursor: pointer !important;
  caret-color: transparent !important;
}

.planning-horizon-dialog-backdrop {
  background-color: none;
}

.planning-horizon-dialog-panel {
  --mdc-dialog-container-elevation: 0;
}

a.modal-link {
  color: var(--interactive-fg-primary-link);
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.ag-grid-checkbox {
  color: var(--input_control-border-bounds);
}

/* For row selection checkbox in tree grids */
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--input_control-fg-selected) !important;
}

#sage-hierarchy-tree-move .ag-row-selected .ag-selection-checkbox,
#hierarchy-tree-move .ag-row-selected .ag-selection-checkbox {
  pointer-events: none;
}

.slideout-footer {
  height: 60px;
  border-top: 1px solid var(--border-bounds);
  background: var(--bg-tertiary) !important;
}

.mat-calendar-period-button.mdc-button.mat-mdc-button {
  --mat-mdc-button-persistent-ripple-color: inherit;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0);
}

.errorLabel {
  color: var(--system-fg-error) !important;
  font-weight: 500 !important;
}

.ag-theme-balham,
.ag-theme-material {
  --ag-selected-row-background-color: var(--system-bg-secondary-accent) !important;
}

.cell-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.e2g-link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--interactive-fg-primary-link);
}

.page-title {
  color: var(--interactive-fg-tertiary-default);
  font-weight: bold;
  font-size: 22px;
}

.title {
  color: var(--interactive-fg-tertiary-default);
}

/* Remove padding from ngb-toast body */
.toast-body {
  padding: 0px !important;
}

ngb-toast {
  max-width: none !important;
}

/*
  Required for the correct sizing/display of @equityeng/graphics component.

  Note:

  As the graphics component gets added to the DOM through a mat-sidebar in 'scra-client/../sage-container', and not
  inside the 'sage-form-outlet', these css class rules are temporarily getting declared globally until some refactoring
  can shift formatting logic back into sage-forms.
*/

app-sage-container .sage-graphics-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

app-sage-container .graphics-component {
  display: flex;
  height: 100%;
  width: 100%;
}

app-sage-container .graphics-row {
  display: flex;
  flex-wrap: wrap;
}

.sage-popover {
  color: var(--interactive-fg-primary-default);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
}
